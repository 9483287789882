import { Component, Inject, Vue } from "vue-property-decorator";
import type { Ref } from "@/types/utils";
import type { FilterData } from "../components/ReportFilters.vue";
import {
  assign,
  find,
  findIndex,
  forEach,
  isArray,
  isString,
  unset,
} from "lodash";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import useReport from "@/composables/report";

@Component
export default class ReportFilterMixin extends Vue {
  @Inject() readonly obReportFilters!: Ref<FilterData>;
  @Inject() readonly obReportHeaders!: Ref<DataTableHeader[]>;

  get obFilterData(): Partial<FilterData> {
    return this.obReportFilters.value;
  }

  get arHeaders(): DataTableHeader[] {
    return this.obReportHeaders.value;
  }

  assignFilter(obData: Partial<FilterData>, cb?: () => void) {
    assign(this.obReportFilters.value, obData);
    cb && cb();
  }

  replaceFilter(obData: Partial<FilterData>, cb?: () => void) {
    this.obReportFilters.value = obData;
    cb && cb();
  }

  setFilter(sKey: keyof FilterData, sValue: any, cb?: () => void) {
    this.$set(this.obReportFilters.value, sKey, sValue);
    cb && cb();
  }

  unsetFilter(sKey: keyof FilterData, cb?: () => void) {
    unset(this.obReportFilters.value, sKey);
    cb && cb();
  }

  // HEADERS

  setDefaultHeaders() {
    const obReportHelper = useReport("sub_daily", "collect");
    this.setHeaders(obReportHelper.report.arDefaultHeaders);
  }

  setHeaders(arHeaders: DataTableHeader[]) {
    this.obReportHeaders.value = arHeaders;
  }

  addHeaders(arHeaders: DataTableHeader[]) {
    forEach(arHeaders, (obItem: DataTableHeader) => this.addHeader(obItem));
  }

  addHeader(obHeader: DataTableHeader, index?: number) {
    if (this.hasHeader(obHeader.value)) {
      return;
    }

    if (index) {
      this.obReportHeaders.value.splice(index, 0, obHeader);
      return;
    }

    this.obReportHeaders.value.push(obHeader);
  }

  removeHeader(sValue: string | string[]) {
    if (isArray(sValue)) {
      forEach(sValue, (sItem) => this.removeHeader(sItem));
      return;
    }

    if (isString(sValue) && this.hasHeader(sValue)) {
      const idx = findIndex(this.arHeaders, (item) => item.value == sValue);
      if (idx !== -1) {
        this.obReportHeaders.value.splice(idx, 1);
      }
    }
  }

  hasHeader(sValue: any, sKey: keyof DataTableHeader = "value"): boolean {
    return find(this.arHeaders, { [sKey]: sValue }) !== undefined;
  }
}
